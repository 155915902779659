#products {
  height: auto;
  padding: 3rem;
}

.pHead {
  width: 100%;
  text-align: center;
}
h1.productHead {
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  /* width: 100%; */
  margin-bottom: 40px;
  display: inline-block;
  padding-right: 80px;
  padding-left: 80px;
  color: #1d92f2;
}
h1.productHead::after,
h1.productHead::before {
  position: absolute;
  top: 50%;
  content: "";
  width: 70px;
  height: 4px;
  background: #1d92f2;
}
h1.productHead::before {
  right: 0;
}
h1.productHead::after {
  left: 0;
}

.productsWrapper .slick-slide > div {
  display: flex;
  justify-content: center;
  height: 480px;
  align-items: center;
  width: 100%;
}

.productsWrapper .prcard {
  position: relative;
  width: 240px !important;
  height: 300px !important;
  /* margin: 30px 10px; */
  display: flex !important;
  flex-direction: column;
  transition: 0.5s;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  outline: none;
  border: none;
  background: #fff;
  padding: 15px;
}
.productsWrapper .prcard::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, #1268dd, #18b4c2, #1268dd, #18b4c2);
  border-radius: 10px;
  z-index: -1;
  filter: blur(7px);
}
.prcard .iconBx {
  position: absolute;
  width: 140px;
  height: 140px;
  border: 18px solid #ebebeb;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -28%;
  background: #fff;
}
.prcard .iconBx i {
  font-size: 45px;
  background-image: linear-gradient(to right, #1268dd, #18b4c2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.prcard .phead {
  font-size: 23px;
  background-image: linear-gradient(to right, #1268dd, #18b4c2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 45px;
}
.prcard .prcontent {
  font-size: 14px;
  text-align: center;

  padding: 15px 0;
}
.prcard a {
  font-size: 14px;
  /* font-weight: bold; */
  background-image: linear-gradient(to right, #1268dd, #18b4c2);
  /* width: 100%; */
  color: #fff;
  text-align: center;
  padding: 8px 15px;
  border-radius: 20px;
  /* -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
}

@media screen and (max-width: 767px) {
  #products {
    height: auto;
    padding: 1rem;
  }
  h1.productHead {
    font-size: 1rem;
    margin-bottom: 10px;
  }
  .productsWrapper {
    padding: 0 1rem;
  }
}

.partners-wrapper {
  position: relative;
  margin-top: 30px;
  /* background: #ebebeb; */
}
.pHead {
  width: 100%;
  text-align: center;
}
.partners-wrapper h1 {
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  color: #00002e;
  display: inline-block;
  padding-right: 80px;
  padding-left: 80px;
  color: #1d92f2;
}
.partners-wrapper h1::after,
.partners-wrapper h1::before {
  position: absolute;
  top: 50%;
  content: "";
  width: 70px;
  height: 4px;
  background: #1d92f2;
}
.partners-wrapper h1::before {
  left: 0;
}
.partners-wrapper h1::after {
  right: 0;
}

.partners {
  height: auto;
  width: 100%;
  display: flex;
  padding: 4rem 9.375rem;
  flex-wrap: wrap;
}
.partnerlist {
  /* flex: 1; */
  height: 180px;
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.partnerlist.zerodha {
  border-right: 1px solid #252232;
  border-bottom: 1px solid #252232;
}
.partnerlist.upstox {
  border-bottom: 1px solid #252232;
  border-right: 1px solid #252232;
}
.partnerlist.smallcase {
  border-bottom: 1px solid #252232;
}
.partnerlist.hdfc {
  border-right: 1px solid #252232;
}
.partnerlist.adityabirla {
  border-right: 1px solid #252232;
}

.partnerlist img {
  width: 150px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: 0.4s ease-in-out;
}
.partnerlist img:hover {
  -webkit-filter: none; /* Safari 6.0 - 9.0 */
  filter: none;
}
@media screen and (max-width: 767px) {
  .partners {
    padding: 40px 2.25rem 2.25rem;
  }
  .partners-wrapper {
    margin-top: 50px;
  }
  .partnerlist {
    width: 50%;
    height: 130px;
  }
  .partnerlist.zerodha {
    border-right: 1px solid #252232;
    border-bottom: 1px solid #252232;
  }
  .partnerlist.upstox {
    border-bottom: 1px solid #252232;
    border-right: none;
  }
  .partnerlist.smallcase {
    border-left: none;
    border-right: 1px solid #252232;
    border-bottom: 1px solid #252232;
  }
  .partnerlist.hdfc {
    border-right: none;
    border-bottom: 1px solid #252232;
  }
  .partnerlist img {
    width: 100px;
  }
  .partners-wrapper h1 {
    font-size: 1rem;
  }
  .partners-wrapper h1::after,
  .partners-wrapper h1::before {
    width: 60px;
  }
}

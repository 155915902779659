footer {
  position: relative;
  margin-top: 50px;
  width: 100%;
  background: #0d0a1b;
  min-height: 300px;
}

.wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background-size: 1000px 100px;
}
.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  background-position-x: 300px;
}
.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  color: #fff;
}

.fabout,
.address,
.social-media {
  flex: 1;
  padding: 30px;
  color: #fff;
  opacity: 80%;
}
.fabout {
  position: relative;
  top: -25px;
}
.fabout .ImgBx {
  width: 250px;
  border-bottom: 1px solid #fff;
  border-bottom-left-radius: 20px;
  margin-bottom: 10px;
}
.fabout p {
  padding: 10px;
}
.address h1,
.social-media h1 {
  position: relative;
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 10px;
}
.address h1::after,
.social-media h1::after {
  position: absolute;
  top: 50%;
  content: "";
  width: 100px;
  height: 3px;
  background: #fff;
  margin-left: 10px;
}

.address h4 {
  font-weight: normal;
  padding: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}
.address h4 i {
  margin-right: 10px;
}
.social-media a {
  font-size: 40px;
  color: #fff;
  margin: 10px;
}
.social-media h3 {
  color: #fff;
  margin-bottom: 10px;
}
.social-media p {
  padding: 5px;
}
.rights {
  text-align: center;
  background: #010011;
  color: #fff;
  text-align: center;
  font-size: small;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: 70%;
  padding: 15px;
}

@media screen and (max-width: 767px) {
  .footer-content {
    flex-direction: column;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem; /*56px*/

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --hue: 208;
  --texthue: 240;
  --first-color: hsl(var(--hue), 83%, 45%);
  --first-color-alt: hsl(var(--hue), 24%, 28%);
  --first-color-light: hsl(var(--hue), 24%, 66%);
  --first-color-lighten: hsl(var(--hue), 24%, 92%);
  --title-color: hsl(var(--texthue), 100%, 09%);
  --text-color: hsl(var(--hue), 4%, 35%);
  --text-color-light: hsl(var(--hue), 4%, 55%);
  --body-color: hsl(var(--hue), 0%, 100%);
  --container-color: #fff;

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/* Responsive typography */
@media screen and (min-width: 968px) {
  :root {
    --big-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  margin: var(--header-height) 0 0 0;
  background-color: var(--body-color);
  color: var(--text-color);
  transition: 0.4s; /*For animation dark mode*/
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 1rem 0 1rem;
  background-color: #fff;
}

.section__title,
.section__title-center {
  font-size: var(--h2-font-size);
  margin-bottom: var(--mb-2);
  line-height: 140%;
}

.section__title-center {
  text-align: center;
}

.container {
  max-width: 968px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
}

.main {
  overflow: hidden; /*For animation*/
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }

  .home__img {
    width: 180px;
  }
  .home__title {
    font-size: var(--h1-font-size);
  }

  .steps__bg {
    padding: 2rem 1rem;
  }
  .steps__card {
    padding: 1.5rem;
  }

  .product__container {
    grid-template-columns: 0.6fr;
    justify-content: center;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .steps__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .product__description {
    padding: 0 4rem;
  }
  .product__container {
    grid-template-columns: repeat(2, 170px);
    justify-content: center;
    column-gap: 5rem;
  }

  .footer__subscribe {
    width: 400px;
  }
}

@media screen and (min-width: 767px) {
  body {
    margin: 0;
  }

  .questions__container {
    align-items: flex-start;
  }

  .footer__container {
    column-gap: 3rem;
  }
  .footer__subscribe {
    width: initial;
  }
}

/* For large devices */
@media screen and (min-width: 992px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .section {
    padding: 8rem 0 1rem;
  }
  .section__title,
  .section__title-center {
    font-size: var(--h1-font-size);
  }

  .about__img {
    width: 380px;
  }

  .steps__container {
    grid-template-columns: repeat(3, 1fr);
  }
  .steps__bg {
    padding: 3.5rem 2.5rem;
  }
  .steps__card-title {
    font-size: var(--normal-font-size);
  }

  .product__description {
    padding: 0 16rem;
  }
  .product__container {
    padding: 4rem 0;
    grid-template-columns: repeat(3, 185px);
    gap: 4rem 6rem;
  }
  .product__img {
    width: 160px;
  }
  .product__circle {
    width: 110px;
    height: 110px;
  }
  .product__title,
  .product__price {
    font-size: var(--normal-font-size);
  }

  .questions__container {
    padding: 1rem 0 4rem;
  }
  .questions__title {
    text-align: initial;
  }
  .questions__group {
    row-gap: 2rem;
  }
  .questions__header {
    padding: 1rem;
  }
  .questions__description {
    padding: 0 3.5rem 2.25rem 2.75rem;
  }

  .footer__logo {
    font-size: var(--h3-font-size);
  }
  .footer__container {
    grid-template-columns: 1fr 0.5fr 0.5fr 0.5fr;
  }
  .footer__copy {
    margin: 7rem 0 2rem;
  }
}

@media screen and (min-width: 1200px) {
  .about__container {
    column-gap: 7rem;
  }

  .scrollup {
    right: 3rem;
  }
}

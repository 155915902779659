.secquote {
  width: 100%;
  padding: 1.5rem;
}
.quote {
  width: 100%;
  max-width: 1190px;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  margin: 0 auto;
}
.qr {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qr i {
  color: #eaeaea;
  font-size: 50px;
}
.q {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.q .img img {
  width: 120px;
  border-radius: 40px;
  border: 7px solid #ebebeb;
}
.q .qt {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: #1d1b4c;
}
.q .imgw img {
  width: 120px;
}

@media screen and (max-width: 767px) {
  .quote {
    align-items: baseline;
  }
  .qr i {
    font-size: 40px;
  }
  .q .qt {
    font-size: 15px;
  }
  .q .img img {
    width: 80px;
  }
  .q .imgw img {
    width: 110px;
  }
}

@media screen and (max-width: 600px) {
  .qr i {
    font-size: 35px;
  }
  .q .qt {
    font-size: 13px;
  }
}

#coursedetails {
  margin-top: 25px;
  margin-bottom: 70px;
}
.courseWrapper {
  padding: 3rem;
  margin-top: 10px;
}
.courseDetails {
  display: flex;
  flex-wrap: wrap;
}
.courseImg {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.courseImg img {
  width: 500px;
}
.coursedata {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.ctitle {
  font-size: 48px;
  font-weight: bolder;
  margin-bottom: 10px;
}
.cprice {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cprice p {
  font-size: 20px;
  margin-right: 15px;
  font-weight: bold;
}

.cprice p.oprice {
  color: red;
  text-decoration: line-through;
}
.cprice p.dprice {
  color: #26d7ab;
}
.csubtitle {
  font-size: 20px;
  font-weight: 600;
}
.coursedata ul {
  margin-top: 10px;
  margin-bottom: 15px;
}
.coursedata ul li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #39385a;
  display: flex;
  align-items: center;
}
.coursedata ul li i {
  margin-right: 6px;
}
a.cbtn {
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  background: linear-gradient(to right, #1268dd, #18b4c2);
  border-radius: 35px;
  color: #fff;
  transition: 0.5s ease-in-out;
  text-align: start !important;
  width: fit-content;
  margin-bottom: 20px;
}
.instructorWrapper {
  padding: 1.5rem;
  /* background: #ebebeb; */
}
.iHead {
  width: 100%;
  text-align: center;
}
h1.instructorHead {
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  /* width: 100%; */
  margin-bottom: 40px;
  display: inline-block;
  padding-right: 80px;
  padding-left: 80px;
  color: #1d92f2;
}
h1.instructorHead::after,
h1.instructorHead::before {
  position: absolute;
  top: 50%;
  content: "";
  width: 70px;
  height: 4px;
  background: #1d92f2;
}
h1.instructorHead::before {
  right: 0;
}
h1.instructorHead::after {
  left: 0;
}
.instructor {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
}
.instructorImg {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-right: 60px; */
}
.instructorDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* padding-left: 60px; */
}
/* .instructorDetails ul {
  list-style-type: disc;
} */
.instructorDetails ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}
.instructorDetails ul li {
  font-size: 16px;
  color: #39385a;
  /* font-weight: 900; */
  /* line-height: 2; */
  /* margin-bottom: 25px; */
  display: flex;
  align-items: center;
}
.instructorDetails ul li i {
  margin-right: 7px;
}
.instructorImg > img {
  width: 350px;
  border-radius: 30px;
}
.instuctorSocial {
  display: flex;
}
.sIcon {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: auto;
  align-items: center;
  justify-content: space-around;
}
.followerCounty {
  margin-top: 4px;
  font-size: 30px;
  line-height: 59px;
  text-align: center;
  font-feature-settings: "liga" 0;
  background-image: linear-gradient(rgb(255, 0, 0) 0%, rgb(254, 154, 37) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.followerCountt {
  margin-top: 4px;
  font-size: 30px;
  line-height: 59px;
  text-align: center;
  font-feature-settings: "liga" 0;
  background-image: linear-gradient(
    rgb(101, 202, 238) 0%,
    rgb(53, 175, 223) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.followerCounti {
  margin-top: 4px;
  font-size: 30px;
  line-height: 59px;
  text-align: center;
  font-feature-settings: "liga" 0;
  background-image: linear-gradient(
    rgb(208, 29, 110) 0%,
    rgb(251, 160, 69) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.sImg img {
  width: 40%;
  border-radius: 0px;
  margin: 0 auto;
}
.courseModules {
  padding: 30px;
}
.msubtitle {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}
.moduleWrapper {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
}
.modules {
  width: 50%;
  margin-bottom: 30px;
}
.modules h2 {
  font-size: 20px;
  margin-bottom: 15px;
}
.modules h2 span {
  color: #26d7ab;
}

.modules p {
  color: #39385a;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.modules p i {
  margin-right: 6px;
}
@media screen and (max-width: 767px) {
  #coursedetails {
    margin-bottom: 100px;
  }
  .courseModules {
    padding: 10px;
  }
  .courseWrapper {
    padding: 1.5rem;
  }
  .courseDetails {
    flex-direction: column;
  }
  .ctitle {
    font-size: 28px;
  }
  .csubtitle {
    font-size: 20px;
  }
  .moduleWrapper {
    flex-direction: column;
  }
  .modules {
    width: 100%;
    margin-bottom: 15px;
  }
  .instuctorSocial {
    margin-bottom: 20px;
  }
  .instructorWrapper {
    padding: 0;
  }
  .instructor {
    flex-direction: column;
  }
  .instructorDetails ul li {
    margin-bottom: 20px;
  }
  h1.instructorHead {
    font-size: 1.2rem;
  }
  .instuctorSocial a {
    font-size: 50px;
  }
  .followerCounty {
    font-size: 20px;
  }
  .followerCountt {
    font-size: 20px;
  }
  .followerCounti {
    font-size: 20px;
  }
  .sIcon {
    width: 120px;
    height: auto;
  }
  a.cbtn {
    font-size: 15px !important;
  }
  .modules h2 {
    font-size: 16px;
  }
}

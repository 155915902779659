/*=============== HEADER ===============*/
.header {
  background: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  transition: 0.4s; /*For animation dark mode*/
}

/*=============== NAV ===============*/
.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.nav__logo,
.nav__toggle,
.nav__close {
  color: var(--title-color);
}

.nav__logo {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -1px;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  transition: 0.3s;
}
.nav__logo img {
  width: 250px;
}
.nav__logo-icon {
  font-size: 1.15rem;
  color: var(--first-color);
}

.nav__logo:hover {
  color: var(--first-color);
}

.nav__toggle {
  display: inline-flex;
  font-size: 1.25rem;
  cursor: pointer;
}
.navAddress {
  display: none;
}
.navSocial {
  display: none;
}
@media screen and (max-width: 767px) {
  .nav {
    justify-content: space-between;
    max-width: 600px;
  }
  .nav__btns {
    margin-right: 20px;
  }
  .nav__menu {
    position: fixed;
    background-color: var(--container-color);
    width: 80%;
    height: 100%;
    top: 0;
    right: -100%;
    box-shadow: -2px 0 4px hsla(var(--hue), 24%, 15%, 0.1);
    padding: 3rem 0 0 0.6rem;
    border-radius: 1rem 0 0 1rem;
    transition: 0.3s;
    z-index: var(--z-fixed);
  }
  .nav__item {
    padding-bottom: 0.5rem !important;
  }
  .nav__logo img {
    width: 150px;
  }
  .nav__list {
    display: flex;
    flex-direction: column;
  }
  .navAddress {
    display: flex;
    flex-direction: column;
  }
  .navAddress .navContent {
    display: flex;
    padding: 10px;
    align-items: center;
  }
  .navAddress .navContent .picon {
    margin-right: 20px;
    margin-left: 10px;
    background-image: linear-gradient(to right, #1268dd, #18b4c2);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .navAddress .navContent .picon i {
    font-size: 20px;
    color: #00002e;
  }
  .navAddress .navContent .pcontent p {
    font-size: 16px;
    color: #00002e;
  }
  .navAddress .navContent .pcontent span {
    font-size: 16px;
    color: #39385a;
  }
  .navSocial {
    display: block;
    margin-left: 20px;
    margin-top: 20px;
  }
  .navSocial h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .navSocial a {
    padding: 10px;
    font-size: 30px;
    /* color: #39385a; */
    background-image: linear-gradient(to right, #1268dd, #18b4c2);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.nav__close {
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  cursor: pointer;
}

.nav__list {
  display: flex;
  flex-direction: column;
  /* row-gap: 1.5rem !important; */
}

.nav__item {
  padding: 1rem;
}
.nav__link {
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: 0.3s;
}

.nav__link:hover {
  color: var(--first-color);
}

/* Show menu */
.show-menu {
  right: 0;
}

/* Change background header */
.scroll-header {
  box-shadow: 0 1px 4px hsla(var(--hue), 4%, 15%, 0.1);
}

/* Active link */
.active-link {
  position: relative;
  color: var(--first-color);
}

.active-link::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: var(--first-color);
}

@media screen and (min-width: 767px) {
  .nav {
    height: calc(var(--header-height) + 1.5rem);
    /* column-gap: 3rem; */
    width: 100%;
  }
  .nav__toggle,
  .nav__close {
    display: none;
  }
  .nav__list {
    flex-direction: row;
    /* column-gap: 3rem !important; */
  }
  .nav__menu {
    margin-left: auto;
  }
}

.services-wrapper {
  margin: 90px;
  position: relative;
}
.sHead {
  width: 100%;
  text-align: center;
}
.services-wrapper h1 {
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  /* width: 100%; */
  margin-bottom: 40px;
  display: inline-block;
  padding-right: 80px;
  padding-left: 80px;
  color: #1d92f2;
}
.services-wrapper h1::after,
.services-wrapper h1::before {
  position: absolute;
  top: 50%;
  content: "";
  width: 70px;
  height: 4px;
  background: #1d92f2;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
  height: 500px;
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  width: 47px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.far.fa-arrow-alt-circle-right,
.far.fa-arrow-alt-circle-left {
  color: grey !important;
  font-size: 35px;
}
.slick-arrow.slick-prev::before,
.slick-arrow.slick-next::before {
  display: none;
}
/* .slick-arrow.slick-prev {
  left: -10px !important;
}

.slick-arrow.slick-next {
  right: -10px !important;
} */
.services-wrapper h1::before {
  right: 0;
}
.services-wrapper h1::after {
  left: 0;
}

@media screen and (max-width: 767px) {
  .services-wrapper {
    margin: 50px;
  }
  .services-wrapper h1 {
    font-size: 1rem;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 600px) {
  .services-wrapper {
    margin: 35px;
  }
}
@media screen and (max-width: 420px) {
  .services-wrapper {
    margin: 35px;
  }
}

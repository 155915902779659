.testimonialsSection {
  margin-top: 30px;
}
.testimonials {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tHead {
  width: 100%;
  text-align: center;
}
.testimonialsSection h1 {
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  /* width: 100%; */
  margin-bottom: 40px;
  display: inline-block;
  padding-right: 80px;
  padding-left: 80px;
  color: #1d92f2;
}
.testimonialsSection h1::after,
.testimonialsSection h1::before {
  position: absolute;
  top: 50%;
  content: "";
  width: 70px;
  height: 4px;
  background: #1d92f2;
}
.testimonialsSection h1::before {
  right: 0;
}
.testimonialsSection h1::after {
  left: 0;
}

.slider-wrapper {
  width: 80%;
}
.slick-arrow.slick-next {
  right: -70px;
}
.slick-arrow.slick-prev {
  left: -75px;
}

.slick-prev::before,
.slick-next::before {
  font-size: 40px !important;
  color: grey !important;
}
.tcard {
  display: flex !important;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* background: chartreuse; */
  margin-left: 15px;
  margin-right: 15px;
}
.slider-wrapper .slick-slider .slick-dots {
  position: relative;
  margin-top: -90px;
}
.timgBx img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid lightgrey;
  padding: 5px;
  margin-bottom: 25px;
}
img.tquote {
  position: absolute;
  width: 90px;
  opacity: 0.1;
}
.tcontent {
  margin-bottom: 18px;
  line-height: 2;
  text-align: justify;
}
.tname {
  margin-bottom: 25px;
  text-align: center;
  width: 100%;
}
.tname > span {
  color: #0d0a1b;
  font-weight: 900;
  font-size: 20px;
}
@media screen and (max-width: 767px) {
  .testimonials {
    height: auto !important;
  }

  .testimonialsSection h1 {
    font-size: 1rem;
  }
  .testimonialsSection h1::after,
  .testimonialsSection h1::before {
    width: 60px;
  }

  .slider-wrapper {
    width: 80%;
  }
  .slick-arrow.slick-next {
    right: -35px;
  }
  .slick-arrow.slick-prev {
    left: -35px;
  }
  .slick-prev::before,
  .slick-next::before {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 600px) {
  .testimonials {
    height: 90vh;
  }
  .slider-wrapper .slick-slider .slick-dots {
    margin-top: -100px;
  }
}

@media screen and (max-width: 420px) {
  .testimonials {
    height: 85vh;
  }
  .slider-wrapper .slick-slider .slick-dots {
    margin-top: -50px;
  }
}

#hero {
  height: 100vh;
  position: relative;
  /* margin-top: 50px; */
}

.heroContainer {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.heroContent {
  flex: 1;
  margin: auto 0;
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.heroContent h1 {
  font-size: 55px;
  font-weight: 900;
  margin-bottom: 10px;
  line-height: 1.3;
  letter-spacing: 0.3px;
}

.heroContent p {
  font-size: 20px;
  margin-bottom: 30px;
}
.heroContent a.herobtn {
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  background: linear-gradient(to right, #1268dd, #18b4c2);
  border-radius: 35px;
  color: #fff;
  transition: 0.5s ease-in-out;
  text-align: start !important;
}
.heroContent a.herobtn:hover {
  font-size: 24px;
}
.heroImg {
  margin: auto 0;
  flex: 1;
}
.heroImg img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #hero {
    height: auto;
  }

  .heroContainer {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .heroContent {
    flex: 1;
    margin: auto 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .heroContent h1 {
    font-size: 38px;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .heroContent p {
    font-size: 15px;
    margin-bottom: 20px;
  }
  .heroContent a {
    font-size: 15px !important;
    text-align: start;
  }
  .heroImg {
    margin: 0 auto;
  }
  .heroImg img {
    width: 600px;
    margin-bottom: 30px;
  }
}

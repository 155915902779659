/*=============== ABOUT ===============*/
.about {
  margin-top: 30px;
  margin-bottom: 80px;
  position: relative;
  /* margin-left: 0 auto; */
}
.aHead {
  width: 100%;
  text-align: center;
}
h1.aboutHead {
  position: relative;
  font-size: 1.5rem;
  font-weight: bold;
  /* width: 100%; */
  margin-bottom: 40px;
  display: inline-block;
  padding-right: 80px;
  padding-left: 80px;
  color: #1d92f2;
}
h1.aboutHead::after,
h1.aboutHead::before {
  position: absolute;
  top: 50%;
  content: "";
  width: 70px;
  height: 4px;
  background: #1d92f2;
}
h1.aboutHead::before {
  right: 0;
}
h1.aboutHead::after {
  left: 0;
}
.about__container {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}
.about__data {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.about__details {
  width: 50%;
}
.about__head {
  font-size: var(--big-font-size);
  font-weight: 800;
  line-height: 140%;
  margin-bottom: var(--mb-1);
}

.about__img {
  width: 500px !important;
  justify-self: center;
}

.about__title {
  margin-bottom: var(--mb-1);
  text-align: center;
}

.about__description {
  text-align: justify;
  line-height: 1.9;
  color: #39385a !important;
}

.about__details {
  display: grid;
  row-gap: 1rem;
  margin-bottom: var(--mb-2-5);
}

.about__details-description {
  display: inline-flex;
  column-gap: 0.5rem;
  font-size: var(--small-font-size);
}

.about__details-icon {
  font-size: 1rem;
  color: var(--first-color);
  margin-top: 0.15rem;
}

/*=============== STEPS ===============*/
.steps__bg {
  background-color: var(--first-color);
  padding: 3rem 2rem 2rem;
  border-radius: 1rem;
}

.steps__container {
  gap: 2rem;
  padding-top: 1rem;
}

.steps__title {
  color: #fff;
}

.steps__card {
  background-color: var(--container-color);
  padding: 2.5rem 3rem 2rem 1.5rem;
  border-radius: 1rem;
}

.steps__card-number {
  display: inline-block;
  background-color: var(--first-color-alt);
  color: #fff;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  font-size: var(--h2-font-size);
  margin-bottom: var(--mb-1-5);
  transition: 0.3s;
}

.steps__card-title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-5);
}

.steps__card-description {
  font-size: var(--small-font-size);
}

.steps__card:hover .steps__card-number {
  transform: translateY(-0.25rem);
}

@media screen and (max-width: 767px) {
  .about {
    margin-bottom: 0px;
  }
  .about__data {
    width: 100%;
  }
  .about__details {
    width: 100%;
  }
  .about__container {
    flex-direction: column;
  }
  h1.aboutHead {
    font-size: 1rem;
  }

  .about__head {
    text-align: center;
  }
  .about__title {
    text-align: center;
    font-size: 26px;
  }
  /* .about__description {
    padding-left: 15px;
    padding-right: 15px;
  } */
}
